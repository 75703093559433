import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": _vm.isMobile ? '90vw' : '700px'
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "pop-title"
  }, [_vm._v(_vm._s(_vm.$lang("Task")))]), _c('div', {
    staticClass: "taskbox"
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("标题")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.title,
      expression: "modifyData.title"
    }],
    domProps: {
      "value": _vm.modifyData.title
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "title", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("图标")]), _c('div', {
    staticClass: "input file-upload"
  }, [_vm.modifyData.icon ? _c('img', {
    attrs: {
      "src": _vm.modifyData.icon,
      "height": "40px",
      "width": "40px"
    }
  }) : _vm._e(), !_vm.modifyData.icon ? _c('svg-icon', {
    attrs: {
      "name": "upload-filled"
    }
  }) : _vm._e(), _c('input', {
    ref: "fileInput",
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": _vm.onFileChange
    }
  })], 1)]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("类型")]), _c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.type,
      expression: "modifyData.type"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modifyData, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("大转盘")])])])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("消耗金币")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.price_coin,
      expression: "modifyData.price_coin"
    }],
    domProps: {
      "value": _vm.modifyData.price_coin
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "price_coin", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("消耗KEY")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.price_stock,
      expression: "modifyData.price_stock"
    }],
    domProps: {
      "value": _vm.modifyData.price_stock
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "price_stock", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("状态")]), _c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.status,
      expression: "modifyData.status"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modifyData, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("启用")]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("禁用")])])])])])]), _c('div', {
    staticClass: "btn",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onConfirm
    }
  }, [_vm._v(_vm._s(_vm.$lang("Submit")))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };