import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "90vh",
      "width": _vm.isMobile ? '90vw' : '700px'
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "pop-title"
  }, [_vm._v(_vm._s(_vm.$lang("资产配置")))]), _c('div', {
    staticClass: "taskbox"
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("链")]), _c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.chain,
      expression: "modifyData.chain"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modifyData, "chain", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.$parent.assetsChains, function (item, index) {
    return _c('option', {
      domProps: {
        "value": item.id
      }
    }, [_vm._v(_vm._s(item.id))]);
  }), 0)])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("符号")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.symbol,
      expression: "modifyData.symbol"
    }],
    domProps: {
      "value": _vm.modifyData.symbol
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "symbol", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("名称")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.name,
      expression: "modifyData.name"
    }],
    domProps: {
      "value": _vm.modifyData.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("图标")]), _c('div', {
    staticClass: "input file-upload"
  }, [_vm.modifyData.icon ? _c('img', {
    attrs: {
      "src": _vm.modifyData.icon,
      "height": "40px",
      "width": "40px"
    }
  }) : _vm._e(), !_vm.modifyData.icon ? _c('svg-icon', {
    attrs: {
      "name": "upload-filled"
    }
  }) : _vm._e(), _c('input', {
    ref: "fileInput1",
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.onFileChange($event, 'fileInput1', 'icon');
      }
    }
  })], 1)]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("可充值")]), _c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.depositable,
      expression: "modifyData.depositable"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modifyData, "depositable", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("是")]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("否")])])])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("可提现")]), _c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.withdrawable,
      expression: "modifyData.withdrawable"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modifyData, "withdrawable", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("是")]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("否")])])])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("提现方式")]), _c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.withdraw_send_type,
      expression: "modifyData.withdraw_send_type"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modifyData, "withdraw_send_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("用户自取")]), _c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("官方代发")])])])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("提现手续费（TON）")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.withdraw_service_fee,
      expression: "modifyData.withdraw_service_fee"
    }],
    domProps: {
      "value": _vm.modifyData.withdraw_service_fee
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "withdraw_service_fee", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("最小提现额")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.withdraw_min_amount,
      expression: "modifyData.withdraw_min_amount"
    }],
    domProps: {
      "value": _vm.modifyData.withdraw_min_amount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "withdraw_min_amount", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("链上证明")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.proof_url,
      expression: "modifyData.proof_url"
    }],
    domProps: {
      "value": _vm.modifyData.proof_url
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "proof_url", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("链上证明按钮")]), _c('div', {
    staticClass: "input file-upload"
  }, [_vm.modifyData.proof_button_image ? _c('img', {
    attrs: {
      "src": _vm.modifyData.proof_button_image,
      "height": "40px"
    }
  }) : _vm._e(), !_vm.modifyData.proof_button_image ? _c('svg-icon', {
    attrs: {
      "name": "upload-filled"
    }
  }) : _vm._e(), _c('input', {
    ref: "fileInput2",
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.onFileChange($event, 'fileInput2', 'proof_button_image');
      }
    }
  })], 1)]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("状态")]), _c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.status,
      expression: "modifyData.status"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modifyData, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("启用")]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("禁用")])])])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("排序")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.sort,
      expression: "modifyData.sort"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.modifyData.sort
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "sort", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-tip"
  }, [_vm._v(" 越小排越前 ")])])]), _c('div', {
    staticClass: "btn",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onConfirm
    }
  }, [_vm._v(_vm._s(_vm.$lang("Submit")))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };