import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "gift",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting'])
  },
  data() {
    return {
      loadConfig,
      isLock: false,
      list: {},
      popTaskShow: false,
      modifyData: {
        "icon": ""
      },
      isLoading: false,
      isExportLoading: false,
      item_id: 0,
      lottery_id: 0,
      total: 0,
      condition: {
        page: 1,
        limit: 15
      },
      time: ["", ""]
    };
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let rs = await this.$http.post("/luckylottery/logs", {
        item_id: this.item_id,
        lottery_id: this.lottery_id,
        time: this.time,
        ...this.condition
      });
      this.isLoading = false;
      if (rs.code == 0) {
        this.list = rs.data.items;
        this.total = rs.data.total;
      }
    },
    async onModify(item) {
      this.modifyData = item;
      this.popTaskShow = true;
    },
    cutStr(str) {
      if (str) return str.replace("T", " ").replace(".000Z", "");
    },
    onRefresh() {
      this.getData();
    },
    onPageChange(e) {
      this.condition.page = e;
      this.getData();
    },
    onPageSizeChange(e) {
      this.condition.limit = e;
      this.getData();
    },
    onDatePickerInput(val) {
      console.log("onDatePickerInput", val, this.time);
    },
    async onExport() {
      this.isExportLoading = true;
      const start_time = this.time[0] || '';
      const end_time = this.time[1] || '';
      let rs = await this.$http.post("/luckylottery/logsExport", {
        item_id: this.item_id,
        lottery_id: this.lottery_id,
        start_time: start_time,
        end_time: end_time
      });
      this.isExportLoading = false;
      if (rs.code === 0) {
        window.open(rs.data.url, '_blank');
      }
    }
  },
  mounted() {
    this.item_id = this.$route.query.item_id || 0;
    this.lottery_id = this.$route.query.lottery_id || 0;
    if (!this.item_id && !this.lottery_id) {
      this.$router.back();
    }
    this.getData();
  }
};