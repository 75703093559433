import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  name: "myDatePicker",
  model: {
    prop: "value",
    //绑定的值，通过父组件传递
    event: "update" //自定义时间名
  },
  props: {
    width: {
      type: String,
      default: "260px"
    },
    height: {
      type: String,
      default: "28px"
    },
    value: {
      type: [String, Number, Date, Array],
      default: ""
    },
    limitDay: {
      type: Number,
      default: 90
    }
  },
  components: {
    DatePicker
  },
  data() {
    return {
      val: "",
      hours: Array.from({
        length: 24
      }).map((_, i) => i)
    };
  },
  methods: {
    onInput(val) {
      this.val = val;
      this.$emit("update:value", val);
      this.$emit("inputFn", val);
    }
  }
};